<template>
  <div class="intro" :key="load">
    <img class="close-tip" src="../assets/tipp/tipp-cross.svg" @click="$router.push('/')">
    <LanguageSwitch class="lang-switch"></LanguageSwitch>
    <!--<iframe :src="$t('introURL')" frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
    <div style="width: 100%; height: 100%; display: flex; flex-direction: row; justify-content: center; align-items: center;">
      <youtube :video-id="$t('introURL')" :player-vars="playerVars" :width="'1244px'" :height="'700px'" ref="youtube" @ended="playerFinish"></youtube>
    </div>
  </div>
</template>

<script>
import LanguageSwitch from "@/components/LanguageSwitch";

export default {
  name: "Intro",
  components: {
    LanguageSwitch
  },
  data() {
    return {
      player: null,
      playerVars: {
        autoplay: 1
      },
      load: 1
    }
  },
  methods: {
    playerFinish() {
      this.$router.push('/')
    }
  },
  watch: {
    '$root.$i18n.locale': function () {
      this.load++;
    }
  }
}
</script>

<style lang="scss" scoped>
.intro {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  padding: 90px;

@include tablet-down {
    padding: 20px;
    padding-top: 70px;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .close-tip {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 35px;
    cursor: pointer;

    @include mobile-down {
      width: 35px;
    }
  }
}

.yt-player {
  width: 100%; /* 90% of viewport vidth */
  height: 50.625%; /* ratio = 9/16 * 90 = 50.625 */
  background: pink;
  max-height: 100%;
  max-width: 160%; /* 16/9 * 90 = 160 */
  margin: auto;
  position: absolute;
  top:0;bottom:0;
  left:0;right:0;
}

.lang-switch {
  position: absolute;
  top: 0;
  right: 10%;

  @include tablet-down {
    right: 20%;
  }
}
</style>